<template>
  <div>
    <b-card no-body>
      <!-- SINGLE DATA -->
      <b-modal
        ref="singleDataModal"
        centered
        :title="'Fichier: ' + singleData.slug"
        ok-only
        ok-title="Fermer"
      >
        <b-card-text>
          Titre:<b> {{ singleData.title }}</b>
          <hr />
        </b-card-text>
        <b-card-text v-if="singleData.direction">
          Direction:<b> {{ singleData.direction.name }}</b>
          <hr />
        </b-card-text>
        <b-card-text v-if="singleData.centre">
          Centre:<b> {{ singleData.centre.name }}</b>
          <hr />
        </b-card-text>
        <b-row>
          <b-col>
            <b-card-text>
              Date de début:<b> {{ singleData.startDate }}</b>
              <hr />
            </b-card-text>
          </b-col>
          <b-col>
            <b-card-text>
              Date de fin:<b> {{ singleData.endDate }}</b>
              <hr />
            </b-card-text>
          </b-col>
        </b-row>
        <b-card-text v-if="singleData.files">
          Pièce jointe:
          <b>
            <a
              v-for="file in singleData.files"
              :key="file"
              :download="singleData.title"
              :href="file"
              title="Télécharger ce fichier"
              target="_blank"
              >Télécharger fichier</a
            ></b
          >
          <hr />
        </b-card-text>
        <b-card-text>
          Description:<b> {{ singleData.content }}</b>
          <hr />
        </b-card-text>
        <b-card-text v-if="singleData.updatedAt">
          Derniere mise à jour:<b>
            {{ this.$coddyger.cdgDateFormat(singleData.updatedAt, true) }}</b
          >
          <hr />
        </b-card-text>
        <b-card-text v-if="singleData.createdAt">
          Date de création:<b>
            {{ this.$coddyger.cdgDateFormat(singleData.createdAt) }}</b
          >
          <hr />
        </b-card-text>
      </b-modal>
      <!-- SAVE/EDIT RECORD -->
      <b-modal
        size="lg"
        id="setDataModal"
        ref="setDataModal"
        :title="Object.entries(this.modaldata.data).length === 0 ? 'Nouveau' : 'Modification'"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          {{ Object.entries(this.modaldata.data).length === 0 ? 'Nouveau' : `Modification ${this.modaldata.data.title}` }}
          <hr />
          <small class="text-danger">* Champs obligatoire</small>
          <hr />
        </h3>

        <b-form @submit.prevent="save">
          <b-row>
            <b-col md="12">
              <!-- DIRECTION -->
              <b-form-group label="* Direction" label-for="direction">
                <v-select
                  id="direction"
                  label="name"
                  value="slug"
                  v-model="formdata.direction"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Sélectionner une direction"
                  :options="formdata.directions"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              
            </b-col>
          </b-row>

          <b-row>
            <b-col md="5">
              <!-- CENTRE -->
              <b-form-group label="* Centre" label-for="centre">
                <v-select
                  id="centre"
                  label="name"
                  value="slug"
                  v-model="formdata.centre"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Sélectionner un centre"
                  :options="formdata.centres"
                />
              </b-form-group>
            </b-col>
            <b-col md="7">
              <!-- TITLE -->
              <b-form-group
                label="* Titre"
                label-for="title"
              >
                <b-form-input
                  v-model="formdata.title"
                  id="title"
                  placeholder="Titre"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>


          <!-- DATES -->
          <b-row>
            <b-col md="6">
              <b-form-group
                  label="* Date de début - ex: 17-03-2021"
                  label-for="startDate"
                >
                  <cleave
                    id="startDate"
                    v-model="formdata.startDate"
                    class="form-control"
                    :raw="false"
                    :options="formdata.options.date"
                    placeholder="DD-MM-YYYY"
                  />
                </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Date de fin - ex: 17-03-2021" label-for="endDate">
                <cleave
                    id="endDate"
                    v-model="formdata.endDate"
                    class="form-control"
                    :raw="false"
                    :options="formdata.options.date"
                    placeholder="DD-MM-YYYY"
                  />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- FILE -->
              <b-form-group
                label="* Pièce Jointe"
                label-for="file"
              >
                <b-form-input
                  v-model="formdata.file"
                  id="file"
                  placeholder="Lien du fichier"
                  
                />
              </b-form-group>
          <!-- CONTENT -->
          <b-form-group
            label="Description"
            label-for="content"
          >
            <b-form-textarea
              rows="4"
              v-model="formdata.content"
              id="content"
              placeholder="Description"
              
            />
          </b-form-group>

          <!-- submit and reset -->
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeModal('save')"
              >
                Fermer
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
                style="float: right"
                :disabled="disableButtonOnLoading"
              >
                <span v-if="!isLoading">Enregistrer</span>
                <span v-if="isLoading">
                  <b-spinner small type="grow"></b-spinner>
                  Chargement...
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <!-- IMPORT RECORD -->
      <b-modal
        id="importModal"
        ref="importModal"
        title="Importation"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          Importation de liste de données
          <hr />
        </h3>

        <b-form @submit.prevent="importer">
          <!-- FILE -->
          <b-form-group
            label="Pièce jointe"
            label-for="file"
          >
            <b-form-file
              @change="this.$coddyger.updateCurrFile"
              v-model="formdata.file"
              id="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              placeholder="Charger un fichier ou glissez-le ici..."
              drop-placeholder="Glisser un fichier ici..."
              size="lg"
              no-drop
              required
            />
            <a
              class="text text-danger"
              v-if="formdata.file"
              @click="emptyFileInput"
              ><small>Retirer fichier</small></a
            >
          </b-form-group>

          <!-- submit and reset -->
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeModal('import')"
              >
                Fermer
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
                style="float: right"
                :disabled="disableButtonOnLoading"
              >
                <span v-if="!isLoading">Importer</span>
                <span v-if="isLoading">
                  <b-spinner small type="grow"></b-spinner>
                  Chargement...
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="tabledata.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tabledata.pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button class="mr-1" variant="primary" @click="openSaveDialog">
              Nouveau
            </b-button>
            <b-button variant="outline-primary"  @click="openImportDialog">
              Importer
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4" offset-md="2">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="tabledata.filter"
                class="d-inline-block mr-1"
                placeholder="Recherche..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="buildData"
        responsive
        :fields="tabledata.columns"
        primary-key="id"
        :current-page="tabledata.currentPage"
        :per-page="tabledata.perPage"
        :filter="tabledata.filter"
        show-empty
        empty-text="Auncune donnée disponible"
        class="position-relative"
      >
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`post-row-${data.item.slug}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mr-1"
              @click.stop="openDialog(data.item)"
            />

            <feather-icon
              :id="`post-row-${data.item.slug}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mr-1"
              @click.stop="buildEditModal(data.item)"
            />

            <feather-icon
              :id="`post-row-${data.item.slug}-trash-icon`"
              icon="TrashIcon"
              size="16"
              class=""
              @click.stop="deleteRecordPrompt(data.item.slug)"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">Total: {{ buildData.length }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="tabledata.currentPage"
              :total-rows="tabledata.totalRows"
              :per-page="tabledata.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  BTooltip,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTimepicker,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BFormTags,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import ModuleVsavms from "@/store/modules/medical/vsavms/vsavms.module";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import * as payloads from './payloads'

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BTooltip,
    BModal,
    BCardText,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    BFormTags,
    BListGroup,
    BListGroupItem,

    vSelect,
    BFormTimepicker,
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  data() {
    return {
      isLoading: false,
      singleData: [], // For single view mode
      locale: "fr",
      isModalActive: false,
      modalData: [],
      // TABLE PARAMS
      tabledata: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15, 30],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        columns: [
          {
            key: "title",
            label: "Titre",
            sortable: true,
            formatter: (val) => `${val}`,
          },
          {
            key: "centre",
            label: "Centre",
            sortable: true,
            formatter: (val) => `${val.name}`,
          },
          {
            key: "type",
            label: "Categorie",
            sortable: true,
            formatter: (val) => `${val}`,
          },
          { key: "actions" },
        ],
        searchTerm: "",
      },
      // Data NEW/EDIT Modal
      formdata: {
        slug: null,
        //-FORM-PROPERTIES-
        title: "",
        centre: "",
        direction: "",
        category: "vsa",
        file: null,
        directions: [],
        centres: [],
        categories: payloads.category,
        startDate: "",
        endDate: "",
        options: {
          date: {
            date: true,
            delimiter: "-",
            datePattern: ["d", "m", "Y"],
          },
        },
      },
      // MODAL TOOLS
      modaldata: {
        data: [],
        isModalActive: false,
      }
    };
  },
  created() {
    if (!ModuleVsavms.isRegistered) {
      this.$store.registerModule("ModuleVsavms", ModuleVsavms);
      ModuleVsavms.isRegistered = true;
    }
    this.$store
      .dispatch("ModuleVsavms/fetch", this.buildCurrentPath().path)
      .then((data) => {})
      .catch((e) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Erreur`,
            icon: "WarningIcon",
            variant: "danger",
            text: `Impossible de charger la liste des données. Merci de réessayer plutard.`,
          },
        });
    });
    this.$store
      .dispatch("ModuleVsavms/fetchDirection")
      .then((res) => {
        this.formdata.directions = res.data.data
      })
      .catch((e) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Erreur`,
            icon: "WarningIcon",
            variant: "danger",
            text: `Impossible de charger la liste des exploitations. Merci de réessayer plutard.`,
          },
        });
    });
    this.$store
      .dispatch("ModuleVsavms/fetchCentre")
      .then((res) => {
        this.formdata.centres = res.data.data
      })
      .catch((e) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Erreur`,
            icon: "WarningIcon",
            variant: "danger",
            text: `Impossible de charger la liste des centres. Merci de réessayer plutard.`,
          },
        });
    });
  },
  computed: {
    buildData() {
      const data = this.$store.state.ModuleVsavms.vsavms;
      this.tabledata.totalRows = data === undefined ? 0 : data.length;

      return data === undefined ? [] : data;
    },
    buildDirection() {
      const data = this.$store.state.ModuleVsavms.direction;
      this.tabledata.totalRows = data === undefined ? 0 : data.length;

      return data === undefined ? [] : data;
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    isModalActive() {
      console.log('enterring watch')
      this.resetValues();
    },
  },

  methods: {
    buildCurrentPath() {
      let path = this.$router.history.current.path;
      let getLastItem = (thePath) =>
        thePath.substring(thePath.lastIndexOf("/") + 1);
      path = getLastItem(path);

      return {
        path: path,
        route: `apps-medical-${path}`
      };
    },
    emptyFileInput() {
      this.formdata.file = null;
    },
    closeModal(object) {
      if (object === "save") {
        this.$refs["setDataModal"].hide();
        this.resetValues();
      } else if (object === "edit") {
        this.$refs["editModal"].hide();
        this.resetValues();
      } else {
        this.$refs["importModal"].hide();
      }
    },
    openSaveDialog() {
      this.$refs["setDataModal"].show();
    },
    openImportDialog() {
      this.$refs["importModal"].show();
    },
    swalError(title, message, type) {
      this.$swal({
        title: title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    openDialog(data) {
      this.$refs.singleDataModal.show();
      this.singleData = data;
    },

    /* ---------------------
    | RECORD REMOVAL HANDLER
    */
    deleteRecordPrompt(id) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { pid: id }),
      });
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Voulez-vous supprimer cet enregistrement ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-danger",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteData();
          } else {
            this.revertUrl();
          }
        });
    },
    deleteData() {
      let id = this.$route.query.pid;
      if (id !== "" || id !== null) {
        this.$store
          .dispatch("ModuleVsavms/remove", {
            item: id,
            path: this.buildCurrentPath().path,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Action Réussie`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Enregistrement supprimé avec succès!`,
              },
            });
            this.revertUrl();
          })
          .catch((error) => {
            if (typeof error === "object") {
              for (let err in error) {
                this.swalError("Erreur", err, "error");
              }
            } else {
              this.$vs.notify({
                title: "Erreur",
                text: error,
                color: "danger",
                icon: "error",
              });
            }
          });
      } else {
      }
    },
    revertUrl() {
      console.log(this.buildCurrentPath().route)
      this.$router.replace({ name: this.buildCurrentPath().route });
    },
    // END ---
    // MODAL FORM TOOL --------------
    resetValues() {
      this.formdata.slug = null;
      this.formdata.title = "";
      this.formdata.direction = [];
      this.formdata.centre = [];
      this.formdata.category = '';
      this.formdata.content = '';
      this.formdata.startDate = '';
      this.formdata.endDate = '';
      this.formdata.file = '';
      this.modaldata.data = {};

      console.log('INPUT RESET SUCCEED...')
    },
    save(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.formdata.title === "") {
        this.swalError("Erreur", this.buildRequiredMessage("titre"), "error");
      } else {
        let global = {
              slug: this.formdata.slug,
              slugDirection: this.formdata.direction.slug,
              slugCentre: this.formdata.centre.slug,
              type: this.formdata.category,
              title: this.formdata.title,
              files: this.$coddyger.stringToArray(this.formdata.file),
              description: this.formdata.content,
              startDate: this.formdata.startDate,
              endDate: this.formdata.endDate,
        };

        this.isLoading = true;

        if (this.formdata.slug !== null && this.formdata.slug !== "") {
          this.$store
            .dispatch("ModuleVsavms/edit", global)
            .then(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Modification effectué avec succès!`,
                },
              });
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);

              this.swalError("Erreur", error.message, "error");
            });
        } else {
          this.$store
            .dispatch("ModuleVsavms/save", global)
            .then(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Enregistrement modifié avec succès!`,
                },
              });
              this.resetValues();
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);

              this.swalError("Erreur", error.message, "error");
            });
        }
      }
    },
    importer(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.formdata.file === "" || this.formdata.file === null) {
        this.swalError(
          "Erreur",
          "Veuillez sélectionner le fichier à importer",
          "error"
        );
      } else {
        let global = {};

        let formData = new FormData();

        formData.append("file", this.formdata.file);
        
        global = {
          formdata: formData,
          path: this.buildCurrentPath().path,
        };
        this.isLoading = true;
        this.$store
          .dispatch("ModuleVsavms/importItem", global)
          .then((doc) => {
            console.log(doc)
            this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Fichier importé avec succès! ${doc.success} lignes importées`,
                },
              });
            
            this.formdata.file = null;
            this.isLoading = false;
            this.$router.go()
          })
          .catch((error) => {
            let status = error.status;
            let message = error.message;
            let data = error.data;

            if (status === 422) {
              this.swalError("Erreur", message, "error");
            } else {
              this.swalError("Erreur", message, "error");
            }

            this.isLoading = false;
          });
      }
    },
    buildRequiredMessage(field) {
      return `Le champ ${field} est obligatoire`;
    },
    buildEditModal(data) {
      this.modaldata.data = data;
      this.modaldata.isModalActive = true;

      if (!data) return;
      if (Object.entries(data).length === 0) {
        this.resetValues();
      } else {
        const { slug, title, type, startDate, endDate, description, centre, direction, files } = JSON.parse(
          JSON.stringify(data)
        );
        
        this.formdata.slug = slug;
        this.formdata.title = title;
        this.formdata.category = type;
        this.formdata.content = description;
        this.formdata.startDate = startDate;
        this.formdata.endDate = endDate;
        this.formdata.file = (Array.isArray(files) && files.length) ? files[0] : "";
        this.formdata.centre = {
          slug: centre.slug,
          name: centre.name
        };
        this.formdata.direction = {
          slug: direction.slug,
          name: direction.name
        };
      }

      this.$refs['setDataModal'].show()
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDesc } = $themeConfig.app;

    return {
      appName,
      appLogoImage,
      appDesc,
    };
  },
};
</script>

import axios from '@/libs/axios.js'

let accessToken = localStorage.getItem('accessToken');

export default {
  save ({ commit }, payloads) {
    console.log(payloads)
    return new Promise((resolve, reject) => {
      axios.post('/vsavms/save', payloads, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        if(response.status === 200) {
          commit('ADD_ITEM', Object.assign(response.data.data, {slug: response.data.data.slug}));
          resolve(response.data.data)
        } else {
          console.log('rejected from then')
          reject(response.data)
        }
      }).catch((error) => {
        console.log('rejected from catch')
        if (error.response) {
          let data = error.response.data;
          reject(data)
        } else {
          reject(error)
        }
      })
    })
  },
  saveDirection ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.post('/vsavms/save/direction', payloads, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        if(response.status === 200) {
          commit('ADD_ITEM_DIRECTION', Object.assign(response.data.data, {slug: response.data.data.slug}));
          resolve(response.data.data)
        } else {
          console.log('rejected from then')
          reject(response.data)
        }
      }).catch((error) => {
        console.log('rejected from catch')
        if (error.response) {
          let data = error.response.data;
          reject(data)
        } else {
          reject(error)
        }
      })
    })
  },  
  importItem ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.post(payloads.path + '/import', payloads.formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        //commit('ADD_ITEM', Object.assign(response.data.data, {slug: response.data.data.slug}));
        resolve(response.data.data)
      }).catch((error) => {
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  fetch ({ commit }, category) {
    return new Promise((resolve, reject) => {
      axios.get('/vsavms/select/programme/' + category, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          commit('SET_LIST', response.data.data);
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          commit('SET_LIST', []);
          reject([]) 
        })
    })
  },
  fetchDirection ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/vsavms/select/direction', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          commit('SET_DIRECTIONS', response.data.data);
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          commit('SET_DIRECTIONS', []);
          reject([]) 
        })
    })
  },
  fetchCentre ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/vsavms/select/centre', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          commit('SET_CENTRES', response.data.data);
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          commit('SET_DIRECTIONS', []);
          reject([]) 
        })
    })
  },
  edit ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.put('/vsavms/update', payloads, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        if(response.status === 200) {
          resolve(response.data.data)
          commit('UPDATE_ITEM', response.data.data);
        } else {
          reject(response.data)
        }
      }).catch((error) => {
        console.log(error)
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  editDirection ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.put('/vsavms/update/direction', payloads, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        if(response.status === 200) {
          resolve(response.data.data)
          commit('UPDATE_ITEM_DIRECTION', response.data.data);
        } else {
          reject(response.data)
        }
      }).catch((error) => {
        console.log(error)
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  remove ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.delete(`/vsavms/delete/data/${payloads.item}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        commit('REMOVE_ITEM', payloads.item);
        resolve(response)
      }).catch((error) => {
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  removeDirection ({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.delete(`/vsavms/delete/direction/${payloads.item}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        commit('REMOVE_ITEM_DIRECTION', payloads.item);
        resolve(response)
      }).catch((error) => {
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  fetchItem (context, payloads) {
    return new Promise((resolve, reject) => {
      axios.get(`/${payloads.path}/select/${payloads.slug}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if(response.status === 200) {
            resolve(response.data.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
}

export default {
  ADD_ITEM (state, item) {
    state.vsavms.unshift(item)
  },
  ADD_ITEM_DIRECTION (state, item) {
    state.directions.unshift(item)
  },
  SET_LIST (state, data) {
    state.vsavms = data
  },
  SET_PROGRAMMES (state, data) {
    state.programmes = data
  },
  SET_CENTRES (state, data) {
    state.centres = data
  },
  SET_FORM_CENTRES (state, data) {
    state.form_centres = data
  },
  SET_DIRECTIONS (state, data) {
    state.directions = data
  },
  SET_FORM_DIRECTIONS (state, data) {
    state.form_directions = data
  },
  UPDATE_ITEM (state, data) {
    const dataIndex = state.vsavms.findIndex((p) => p.slug === data.slug);
    Object.assign(state.vsavms[dataIndex], data)
  },
  UPDATE_ITEM_DIRECTION (state, data) {
    const dataIndex = state.directions.findIndex((p) => p.slug === data.slug);
    Object.assign(state.directions[dataIndex], data)
  },
  REMOVE_ITEM (state, itemId) {
    const ItemIndex = state.vsavms.findIndex((p) => p.slug === itemId);
    state.vsavms.splice(ItemIndex, 1)
  },
  REMOVE_ITEM_DIRECTION (state, itemId) {
    const ItemIndex = state.directions.findIndex((p) => p.slug === itemId);
    state.directions.splice(ItemIndex, 1)
  },
}
